import React, { useState } from "react";
import { Link } from "@ludens-reklame/rubics-v3-sdk/link/types";
import { useStore } from "@ludens-reklame/rubics-v3-react/store/hooks";
import { hamburger, close } from "../../icons/svg.js";

interface Props {
  links?: { link?: Link }[];
}

const VerticalMenu: React.FC<Props> = ({ links = [] }) => {
  const [menuActive, setMenuActive] = useState<boolean>(false);
  const context = useStore().context;

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <div className="menu-container mw hs vs-xl">
      <div className="menu-content">
        {links && links.length > 0 && (
          <div className="menu-links mobile">
            <a
              className={`link-item h6 ${
                context.url.pathname === links[0].link?.url ? "active" : ""
              }`}
              href="#"
              onClick={(e) => {
                toggleMenu();
                e.preventDefault();
              }}
            >
              Meny
              <button className={`menu-icon ${menuActive ? "active" : ""}`}>
                {menuActive ? close : hamburger}
              </button>
            </a>
          </div>
        )}
        <div className={`menu-links ${menuActive ? "open" : "closed"}`}>
          {links &&
            links.map((link, index) => (
              <a
                key={index}
                className={`link-item h6  ${
                  context.url.pathname === link.link?.url ? "active" : ""
                }`}
                href={link.link?.url}
              >
                {link.link?.title}
              </a>
            ))}
        </div>
      </div>
    </div>
  );
};

export default VerticalMenu;
